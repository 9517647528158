import { format, formatDistanceToNowStrict } from 'date-fns';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import Select, { type SingleValue } from 'react-select';
import { match } from 'ts-pattern';

import {
  type DtoOrgSubscription,
  type DtoProduct,
  type DtoProductFeature,
  EnumsOrgSubscriptionPlan,
  EnumsProductBillingInterval,
  EnumsProductFeatureType,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../utils/assets';
import { formatCurrency } from '../../utils/currency';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { ProductUtils, useOpenLetsChatWindow } from './utils';

export function SeatPicker(props: {
  prices: DtoProduct['prices'];
  selected: ModelsPrice | null | undefined;
  onSelect: (price: ModelsPrice) => void;
}): JSX.Element | null {
  const { prices, selected, onSelect } = props;
  const priceOptions = useMemo<Option<ModelsPrice>[]>(() => {
    if (!prices) return [];

    const ps = prices.filter((o) => !o.archived);
    const sortedPrices = ps.sort((a, b) => a.maxSeats - b.maxSeats);

    const r: Option<ModelsPrice>[] = [];
    let prevMaxSeats = 0;
    for (let i = 0; i < sortedPrices.length; i++) {
      const price = sortedPrices[i];
      r.push({
        label: `${prevMaxSeats + 1} - ${price.maxSeats}`,
        value: price,
      });
      prevMaxSeats = price.maxSeats;
    }
    if (r.length > 1) {
      r.push({
        label: `${prevMaxSeats + 1}+`,
        value: {
          id: 'letschat',
          maxSeats: Infinity,
          stripePriceId: null,
          amount: -1,
          billingInterval:
            EnumsProductBillingInterval.ProductBillingIntervalQuarterly,
          archived: false,
        },
      });
    }
    return r;
  }, [prices]);
  const selectedOption = useMemo<Option<ModelsPrice> | null>(() => {
    if (!selected) return null;
    return (
      priceOptions.find((option) => option.value.id === selected?.id) ?? null
    );
  }, [priceOptions, selected]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<ModelsPrice>>()
  );
  const handleChange = (option: SingleValue<Option<ModelsPrice>>) => {
    if (!option) return;
    onSelect(option.value);
  };

  return (
    <Select<Option<ModelsPrice>, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={selectedOption}
      options={priceOptions}
      onChange={handleChange}
      isSearchable={false}
    />
  );
}

function LetsChatButton(props: {
  selectedProduct: DtoProduct;
  disabled?: boolean;
}) {
  const letsChat = useOpenLetsChatWindow();
  return (
    <button
      type='button'
      className='btn w-full h-12.5 btn-primary'
      onClick={letsChat}
      disabled={props.disabled}
    >
      Let’s Chat!
    </button>
  );
}

function YourPlanLabel() {
  return (
    <div className='h-7.5 bg-white text-black text-xs font-bold p-2 rounded-t-md'>
      Your Current Plan
    </div>
  );
}

function CancelMyPlanButton(props: {
  onClick?: () => void;
  cancelAt?: string | null;
  disabled?: boolean;
}) {
  return props.cancelAt ? (
    <div className='h-12.5 flex items-center justify-center text-center text-sms text-icon-gray'>
      <div>
        Cancels on{' '}
        <strong>{format(new Date(props.cancelAt), 'MMMM d, yyyy')}</strong>
      </div>
    </div>
  ) : (
    <button
      type='button'
      className='btn h-12.5 text-sms text-icon-gray hover:text-white transition-colors'
      onClick={props.onClick}
      disabled={props.disabled}
    >
      Cancel My Plan
    </button>
  );
}

const controllerIcon = getStaticAssetPath('images/plans-controller-icon.svg');
const liveHostedIcon = getStaticAssetPath('images/plans-live-icon.svg');
const slackToolsIcon = getStaticAssetPath('images/plans-slack-icon.svg');

function FeatureSet(props: {
  featureType: EnumsProductFeatureType;
  featureMap: Map<EnumsProductFeatureType, DtoProductFeature[]>;
}) {
  const { featureType, featureMap } = props;
  const features = featureMap.get(featureType);
  if (!features || features.length === 0) return null;

  const [title, icon] = match(featureType)
    .with(EnumsProductFeatureType.ProductFeatureTypeProgramAccess, () => [
      'Slack Tools',
      slackToolsIcon,
    ])
    .with(EnumsProductFeatureType.ProductFeatureTypeOndGamePlay, () => [
      'Team Games',
      controllerIcon,
    ])
    .with(EnumsProductFeatureType.ProductFeatureTypeLiveBooking, () => [
      'Live-Hosted Events',
      liveHostedIcon,
    ])
    .otherwise(() => ['', '']);

  if (title === '' || icon === '') return null;

  return (
    <div>
      <div className='flex items-center gap-1 font-bold'>
        <img src={icon} alt='' className='w-6.5 h-6.5' />
        {title}
      </div>
      <div className='pl-2 space-y-0.5'>
        {features.map((feature) => (
          <div key={feature.id} className='flex items-start pt-2 text-white'>
            <div className='pt-0.5'>
              <FilledCheckIcon className='w-3.5 h-3.5 fill-current' />
            </div>
            <div className='pl-2 text-sms'>
              {feature.description ?? feature.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ProductCardFeatures(props: {
  features: DtoProductFeature[];
  className?: string;
}) {
  const { features, className } = props;

  const featureMap: Map<EnumsProductFeatureType, DtoProductFeature[]> =
    useMemo(() => {
      if (features.length === 0) return new Map();
      return features.reduce((acc, feature) => {
        const type = feature.type;
        if (!acc.has(type)) acc.set(type, []);
        acc.get(type).push(feature);
        return acc;
      }, new Map());
    }, [features]);

  return (
    <div className={`${className ?? 'flex gap-6 flex-col'}`}>
      <FeatureSet
        featureType={EnumsProductFeatureType.ProductFeatureTypeProgramAccess}
        featureMap={featureMap}
      />
      <FeatureSet
        featureType={EnumsProductFeatureType.ProductFeatureTypeOndGamePlay}
        featureMap={featureMap}
      />
      <FeatureSet
        featureType={EnumsProductFeatureType.ProductFeatureTypeLiveBooking}
        featureMap={featureMap}
      />
    </div>
  );
}

export function PriceWithMonthlySavings(props: {
  product: DtoProduct;
  price: ModelsPrice;
  className?: string;
}) {
  const { product, price, className = 'font-bold text-3.5xl' } = props;
  const enabled = useFeatureQueryParam('price-as-monthly');

  const costSavings = useMemo(
    () => ProductUtils.MonthlyCostSavings(product, price),
    [price, product]
  );

  if (!enabled) {
    return (
      <div className={className}>
        {formatCurrency(price.amount)}
        <span className='font-normal text-base text-icon-gray'>
          /{ProductUtils.FormatInterval(price.billingInterval)}
        </span>
      </div>
    );
  }

  return (
    <div className={className}>
      <div>
        {formatCurrency(costSavings.monthlyAmountAtGivenPrice)}
        <span className='font-normal text-base text-icon-gray'>/month</span>
      </div>

      {price.billingInterval !==
        EnumsProductBillingInterval.ProductBillingIntervalMonthly && (
        <div className='text-xs font-normal'>
          <div>
            billed {price.billingInterval} at{' '}
            {costSavings.totalMonthlyCostAtGivenPriceInterval && (
              <>
                <span className='line-through'>
                  {formatCurrency(
                    costSavings.totalMonthlyCostAtGivenPriceInterval
                  )}
                </span>{' '}
              </>
            )}
            <span className='text-green-001'>
              {formatCurrency(price.amount)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export type ProductCardProps = {
  product: DtoProduct;
  onClick?: () => void;
  onClickDelete?: () => void;
  onClickSelect?: (
    product: DtoProduct,
    price: ModelsPrice | null,
    trial: boolean
  ) => void;
  onClickCancel?: () => void;
  onSelectedPriceChange?: (price: ModelsPrice | null) => void;
  noHoverEffect?: boolean;
  preSelectForSeats?: number;
  disabled?: boolean;
  currentSubscription?: DtoOrgSubscription;
  actionSheet?: React.ReactNode;
  canTrial?: boolean;
  billingInterval: EnumsProductBillingInterval;
  noYourPlanLabel?: boolean;
};

export function ProductCard(props: ProductCardProps): JSX.Element {
  const {
    product,
    onClick,
    onClickDelete,
    onClickCancel,
    noHoverEffect,
    preSelectForSeats,
    disabled,
    currentSubscription,
    actionSheet,
    canTrial,
    billingInterval,
    noYourPlanLabel,
  } = props;

  const prices = useMemo(() => {
    return (
      product.prices?.filter(
        (o) => !o.archived && o.billingInterval === billingInterval
      ) ?? []
    );
  }, [product.prices, billingInterval]);

  const selectedSeatRange = useRef<number | null>(null);
  const [selectedPrice, setSelectedPrice] = useState<ModelsPrice | null>(() => {
    if (!prices) return null;
    prices.sort((a, b) => a.maxSeats - b.maxSeats);

    if (prices.length === 0) return null;

    if (preSelectForSeats !== undefined) {
      const bestPrice = prices.find((o) => o.maxSeats >= preSelectForSeats);
      if (bestPrice) {
        selectedSeatRange.current = bestPrice.maxSeats;
        return bestPrice;
      }
    }

    return prices[0];
  });

  const handleSelectedPriceChange = useLiveCallback(
    (price: ModelsPrice | null) => {
      selectedSeatRange.current = price?.maxSeats ?? null;
      setSelectedPrice(price);
      props.onSelectedPriceChange?.(price);
    }
  );

  useLayoutEffect(() => {
    if (!prices) return;
    prices.sort((a, b) => a.maxSeats - b.maxSeats);

    if (prices.length === 0) {
      handleSelectedPriceChange(null);
      return;
    }

    const currentSeats = selectedSeatRange.current;
    if (currentSeats) {
      const bestPrice = prices.find((o) => o.maxSeats >= currentSeats);
      if (bestPrice) {
        handleSelectedPriceChange(bestPrice);
        return;
      }
    }
    handleSelectedPriceChange(prices[0]);
  }, [billingInterval, prices, preSelectForSeats, handleSelectedPriceChange]);

  const isMyCurrentProduct = useMemo(() => {
    if (!currentSubscription || noYourPlanLabel) return false;
    return currentSubscription.productId === product.id;
  }, [currentSubscription, product, noYourPlanLabel]);

  const isMyCurrentPlan = useMemo(() => {
    if (!currentSubscription) return false;
    if (currentSubscription.productId !== product.id) return false;
    if (product.prices && product.prices.length > 0) {
      if (!selectedPrice) return false;
      return currentSubscription.priceId === selectedPrice.id;
    }
    return true;
  }, [currentSubscription, product, selectedPrice]);

  const letsChat = selectedPrice !== null && selectedPrice.amount < 0;
  const hasPrices = Boolean(prices.length > 0);
  const canTrialProduct = Boolean(
    product.trialPeriodDays &&
      product.trialPeriodDays > 0 &&
      selectedPrice &&
      selectedPrice.amount > 0 &&
      canTrial &&
      !currentSubscription?.subscriptionId
  );

  // legacy. permit products with _no_ prices to be recognized as free
  const recognizeAsFree =
    selectedPrice?.amount === 0 ||
    !product.prices ||
    product.prices.length === 0;

  return (
    <div
      className={`
        relative transition-opacity
        ${
          !noHoverEffect && hasPrices
            ? 'transition-transform transform duration-300 hover:scale-105'
            : ''
        }
        ${!hasPrices ? 'opacity-60' : ''}
      `}
    >
      {isMyCurrentProduct && (
        <div className='absolute -top-7.5 left-4 -z-1'>
          <YourPlanLabel />
        </div>
      )}
      <div
        className={`
          flex-none relative
          w-78 h-130
          flex flex-col
          rounded-2xl bg-lp-black-004 border-secondary border
          ${onClick ? 'cursor-pointer' : ''}
        `}
        onClick={onClick}
      >
        {onClickDelete && (
          <div className='absolute -top-1.5 -right-1.5'>
            <button
              type='button'
              onClick={onClickDelete}
              className='btn bg-lp-black-004 transform transition-transform hover:scale-110 active:scale-100 border-secondary border rounded-full p-1.5 text-red-001'
            >
              <DeleteIcon />
            </button>
          </div>
        )}

        <div className='h-[164px] px-7.5 pt-6 flex-none border-b border-secondary'>
          <div className='font-bold'>{product.name}</div>
          <div className='pt-3 font-bold text-3.5xl h-15'>
            {letsChat ? (
              <>Let’s Chat!</>
            ) : recognizeAsFree ? (
              <>Free</>
            ) : !hasPrices || selectedPrice === null ? (
              <>Not Available</>
            ) : (
              <PriceWithMonthlySavings
                product={product}
                price={selectedPrice}
              />
            )}
          </div>
          {hasPrices && (
            <div
              className='py-2 flex items-center gap-2 text-sms'
              onClick={(e) => e.stopPropagation()}
            >
              <span className='text-icon-gray'>Users:</span>
              <SeatPicker
                prices={prices}
                selected={selectedPrice}
                onSelect={handleSelectedPriceChange}
              />
            </div>
          )}
        </div>

        <section className='px-5 pt-4'>
          <ProductCardFeatures features={product.features} />
        </section>

        <div className='mt-auto p-4 pt-0 flex-none flex items-center gap-2'>
          {isMyCurrentPlan ? (
            currentSubscription?.subscriptionId ? (
              <div className='w-full flex justify-center'>
                <CancelMyPlanButton
                  onClick={onClickCancel}
                  cancelAt={currentSubscription.cancelAt}
                  disabled={disabled}
                />
              </div>
            ) : (
              <></>
            )
          ) : letsChat ? (
            <LetsChatButton selectedProduct={product} disabled={disabled} />
          ) : !hasPrices ? (
            <></>
          ) : (
            <button
              type='button'
              className='btn w-full h-12.5 btn-primary'
              disabled={disabled}
              onClick={() =>
                props.onClickSelect?.(product, selectedPrice, canTrialProduct)
              }
            >
              {canTrialProduct ? (
                <>Start a {product.trialPeriodDays} Day Trial</>
              ) : isMyCurrentProduct ? (
                <>Modify My Plan</>
              ) : (
                <>Choose Plan</>
              )}
            </button>
          )}
          {actionSheet}
        </div>
      </div>
      {isMyCurrentProduct && currentSubscription?.trialEnd && (
        <div className='absolute bottom-[-30px] w-full font-bold text-tertiary text-center'>
          {formatDistanceToNowStrict(new Date(currentSubscription.trialEnd))}{' '}
          left in Free Trial
        </div>
      )}
    </div>
  );
}

export function NonmarketedSubscriptionCard(props: {
  onClickCancel?: () => void;
  subscription: DtoOrgSubscription;
  maxSeats?: number | null;
  disabled?: boolean;
}): JSX.Element {
  const { subscription, maxSeats, onClickCancel, disabled } = props;
  const isFree =
    !subscription.amount ||
    subscription.plan === EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree;

  return (
    <div className='relative w-full'>
      <div className='absolute -top-7.5 left-4'>
        <YourPlanLabel />
      </div>
      <div
        className={`
          flex-none relative
          w-full
          flex flex-col
          rounded-2xl bg-lp-black-004 border-secondary border
        `}
      >
        <div className='h-40 px-7.5 pt-6 flex-none flex gap-10'>
          <div>
            <div className='font-bold'>{subscription.productName}</div>
            {!subscription.amount &&
            subscription.plan !==
              EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree ? (
              <></>
            ) : (
              <div className='pt-4 font-bold text-3.5xl h-12.5'>
                {isFree ? (
                  <>Free</>
                ) : (
                  <>
                    {formatCurrency(subscription.amount ?? 0)}
                    {subscription.billingInterval && (
                      <span className='font-normal text-base text-icon-gray'>
                        /
                        {ProductUtils.FormatInterval(
                          subscription.billingInterval
                        )}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            {maxSeats && (
              <div className='py-2 flex items-center gap-2 text-sms text-icon-gray'>
                Max Users: {maxSeats}
              </div>
            )}
          </div>
          <div className='flex-1'>
            <ProductCardFeatures
              features={subscription.features}
              className='flex gap-7.5 flex-row'
            />
          </div>
        </div>

        {!isFree && (
          <section className='pt-4 pb-6 px-7.5 border-t border-secondary flex items-center justify-between gap-4'>
            <div className='font-medium text-icon-gray'>
              You are on a plan or price that we no longer offer. If you cancel,
              modify, or change
              <br />
              your plan you may not be able to return to this plan at this
              price.
            </div>
            {subscription.subscriptionId && (
              <CancelMyPlanButton
                onClick={onClickCancel}
                cancelAt={subscription.cancelAt}
                disabled={disabled}
              />
            )}
          </section>
        )}
      </div>
    </div>
  );
}
